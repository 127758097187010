$(document).ready(function () {
    "use strict";

    const lifeInsuranceCalculator = function ($) {

        var context = $("#LifeCalculator");

        const sh = "_SH";

        const yes = "_Yes";
        const no = "_No";

        const yesNoArray = [
            yes,
            no
        ];

        var maritalStatus = "#MaritalStatus";

        const married = "_Married";
        const single = "_Single";

        const maritalStatusArray = [
            married,
            single
        ];

        // Partner Earnings
        // ================

        var partnerEarnings = "#PartnerEarnings";

        function togglePartnerEarnings() {
            if ($(maritalStatus + married, context).prop("checked")) {
                $(partnerEarnings + sh, context).show();
            } else {
                $(partnerEarnings, context).val("");
                $(partnerEarnings + sh, context).hide();
            }
        }

        // On load
        togglePartnerEarnings();

        // On change
        $.each(maritalStatusArray, function (index, value) {
            $(maritalStatus + value, context).change(function () {
                togglePartnerEarnings();
            });
        });

        // Dependent Children
        // ==================

        const dependentChildren = "#DependentChildren";
        const dependentChildrenArray = ["1", "2", "3", "4", "5", "6", "7", "8"];
        const child = "#Child";
        const age = "Age";

        function toggleDependentChildren() {
            var dependentChildrenValue = $(dependentChildren, context).val();

            $.each(dependentChildrenArray, function (index, value) {
                if (dependentChildrenValue >= value) {
                    $(child + value + age + sh, context).show();
                } else {
                    $(child + value + age + sh, context).hide();
                }
            });
        }

        // On load
        toggleDependentChildren();

        // On change
        $(dependentChildren, context).change(function () {
            toggleDependentChildren();
        });

        // Has Mortgages
        // =============

        const ownHome = "#OwnHome";
        const hasMortgages = "#HasMortgages";
        const mortgages = "#Mortgages";

        function toggleHasMortgages() {
            if ($(ownHome + yes, context).prop("checked")) {
                $(hasMortgages + sh, context).show();
            } else {
                $(mortgages, context).val("");
                $(mortgages + sh, context).hide();
                $(hasMortgages, context).prop("checked", false);
                $(hasMortgages + sh, context).hide();
            }
        }

        // On load
        toggleHasMortgages();

        // On change
        $.each(yesNoArray, function (index, value) {
            $(ownHome + value, context).change(function () {
                toggleHasMortgages();
            });
        });

        // Assets and Liabilities
        // ======================

        const balanceSheetArray = [
            "Mortgages",
            "PersonalLoans",
            "CarLoans",
            "CreditCards",
            "Overdrafts",
            "OtherDebts",
            "OnCallSavings",
            "TermDeposits",
            "KiwiSaver",
            "Superannuation",
            "Shares",
            "InvestmentFunds",
            "ForeignCurrency",
            "OtherFinancialAssets"
        ];

        function toggleBalanceSheet(checkbox, showHide, amount) {
            if ($(checkbox, context).prop("checked")) {
                $(showHide, context).show();
            } else {
                $(amount, context).val("");
                $(showHide, context).hide();
            }
        }

        $.each(balanceSheetArray, function (index, value) {
            var checkbox = `#Has${value}`;
            var amount = `#${value}`;

            // On load
            toggleBalanceSheet(checkbox, amount + sh, amount);

            // On change
            $(checkbox, context).change(function () {
                toggleBalanceSheet(checkbox, amount + sh, amount);
            });
        });

    }(jQuery);

    // End jQuery Document Ready
});