$(document).ready(function () {
    "use strict";

    // Hide When Javascript is Active
    // ==============================

    const hide = function ($) {

        // Function
        function hideOnLoad() {
            $(".js-hide").hide();
        }

        // On load
        hideOnLoad();

    }(jQuery);

    // End jQuery Document Ready
});