$(document).ready(function () {
    "use strict";

    // Expand/Collapse
    // ===============

    (function ($) {
        
        var toggleTitle = ".toggle-title";
        var glyphiconPlus = "icon-chevron-right";
        var glyphiconMinus = "icon-chevron-down";

        const hidden = "d-none";
        const span = "span";

        // Only show option if javascript is enabled.
        $(".expand-collapse").removeClass(hidden);

        // Only collapse panel body if javascript is enabled.
        $(toggleTitle).next().addClass(hidden);

        // Toggle on click.
        $(toggleTitle).click(function () {
            if ($(this).next().hasClass(hidden)) {
                $(this).next().removeClass(hidden);
                $(this).children(span).children(span).removeClass(glyphiconPlus);
                $(this).children(span).children(span).addClass(glyphiconMinus);
            } else {
                $(this).next().addClass(hidden);
                $(this).children(span).children(span).removeClass(glyphiconMinus);
                $(this).children(span).children(span).addClass(glyphiconPlus);
            }
        });

        $(".expand-all").click(function () {
            $(toggleTitle).next().removeClass(hidden);
            $(toggleTitle).children(span).children(span).removeClass(glyphiconPlus);
            $(toggleTitle).children(span).children(span).addClass(glyphiconMinus);
        });

        $(".collapse-all").click(function () {
            $(toggleTitle).next().addClass(hidden);
            $(toggleTitle).children(span).children(span).removeClass(glyphiconMinus);
            $(toggleTitle).children(span).children(span).addClass(glyphiconPlus);
        });

    }(jQuery));


    // Show/Hide
    // =========

    (function ($) {

        const toggleShow = ".toggle-show";
        const hidden = "d-none";

        // Only collapse panel body if javascript is enabled.
        $(toggleShow).next().addClass(hidden);

        // Toggle on click.
        $(toggleShow).click(function () {
            if ($(this).next().hasClass(hidden)) {
                $(this).next().removeClass(hidden);
            } else {
                $(this).next().addClass(hidden);
            }
        });

    }(jQuery));
    
    // End jQuery Document Ready
});