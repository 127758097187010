$(document).ready(function () {
    "use strict";

    // Shared Variables
    // ================

    var yes = "_Yes";
    var no = "_No";

    var yesNo = [
        yes,
        no
    ];

    var sh = "_SH";

    var people = [
        "Person1",
        "Person2",
        "Person3",
        "Person4",
        "Person5",
        "Person6"
    ];

    var benefits = [
        "LifeCover",
        "CriticalConditions",
        "ProgressiveCare",
        "IncomeProtection",
        "PermanentDisablement",
        "FamilyProtection",
        "HealthCover",
        "HealthPlus"
    ];

    var healthBenefits = [
        "HealthCover",
        "HealthPlus"
    ];

    // Add Person
    // ==========

    const addPerson = function ($) {

        $.each(people, function (index, person) {

            // Variables
            const checkbox = `#${person}_AddPerson`;

            // Function
            function togglePerson() {
                if ($(checkbox).prop("checked")) {
                    $(checkbox + sh).show();
                } else {
                    $(checkbox + sh).hide();
                }
            }

            // On load
            togglePerson();

            // On change
            $(checkbox).change(function () {
                togglePerson();
            });

        });

    }(jQuery);

    // Add Children
    // ============

    const addChildren = function ($) {

        // Variables
        const checkbox = "#AddChildren";

        // Function
        function toggleAddChildren() {
            if ($(checkbox).prop("checked")) {
                $(checkbox + sh).show();
            } else {
                $(checkbox + sh).hide();
            }
        }

        // On load
        toggleAddChildren();

        // On change
        $(checkbox).change(function () {
            toggleAddChildren();
        });

    }(jQuery);


    // Add Benefit
    // ===========

    const addBenefit = function ($) {

        $.each(people, function (index, person) {

            $.each(benefits, function (index, benefit) {

                // Variables
                var checkbox = `#${person}_${benefit}_AddBenefit`;

                // Function
                function toggleBenefit() {
                    if ($(checkbox).prop("checked")) {
                        $(checkbox + sh).show();
                    } else {
                        $(checkbox + sh).hide();
                    }
                }

                // On load
                toggleBenefit();

                // On change
                $(checkbox).change(function () {
                    toggleBenefit();
                });

            });

        });

    }(jQuery);

    const addRedundancy = function ($) {

        $.each(people, function (index, person) {

            // Variables
            var redundancyPrefix = `#${person}_IncomeProtection_IncludeRedundancyCover`;
            var redundancyYes = redundancyPrefix + yes;
            var redundancyYesSh = redundancyYes + sh;

            var redundancyAmount = `#${person}_IncomeProtection_RedundancyBenefitAmount`;

            // Function
            function toggleIncludeRedundancy() {
                if ($(redundancyYes).prop("checked")) {
                    $(redundancyYesSh).show();
                } else {
                    $(redundancyAmount).val("");
                    $(redundancyYesSh).hide();
                }
            }

            // On load
            toggleIncludeRedundancy();

            // On change
            $.each(yesNo, function (index, value) {
                $(redundancyPrefix + value).change(function () {
                    toggleIncludeRedundancy();
                });
            });

        });

    }(jQuery);
    
    // Add Health Benefit
    // ==================

    const addHealthBenefit = function ($) {

        $.each(healthBenefits, function (index, benefit) {

            // Variables
            var checkbox = `#${benefit}_AddBenefit`;

            // Function
            function toggleBenefit() {
                if ($(checkbox).prop("checked")) {
                    $(checkbox + sh).show();
                } else {
                    $(checkbox + sh).hide();
                }
            }

            // On load
            toggleBenefit();

            // On change
            $(checkbox).change(function () {
                toggleBenefit();
            });

        });

    }(jQuery);
    
    // Edit Payment Frequency
    // ======================

    const editPaymentFrequency = function ($) {

        $("#Edit_PaymentFrequency").change(function () {
            this.form.submit();
        });

    }(jQuery);

    // End jQuery Document Ready
});