$(document).ready(function () {
    "use strict";

    // Format Number and Currency
    // ==========================

    (function ($) {

        function formatNumber(value) {
            var components = value.toString().split(".");
            var formattedComponents = [];
            var lhs;
            var rhs;

            if (components.length > 0) {
                lhs = components[0].replace(/\D/g, "");

                // Remove any leading zeros.
                if (lhs.length > 1) {
                    while (lhs.charAt(0) === "0") {
                        lhs = lhs.substr(1);
                    }
                }

                lhs = lhs.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (lhs.length > 0) {
                    formattedComponents.push(lhs);
                } else {
                    // Add a zero at the beginning of the string.
                    formattedComponents.push("0");
                }
            }

            if (components.length > 1) {
                rhs = components[1].replace(/\D/g, "");

                if (rhs.length > 0) {
                    formattedComponents.push(rhs);
                }
            }

            return formattedComponents.join(".");
        }

        // Note: This formats number inputs on blur (not on keyup).
        // This provides a more predictable data entry experience for the user.
        $("input.number").blur(function () {
            var value = $(this).val();
            $(this).val(formatNumber(value));
        });

        function formatCurrency(value) {
            var formattedNumber = formatNumber(value);

            // If there is only one number after the decimal point, add a zero to the end.
            if (formattedNumber.charAt(formattedNumber.length - 2) === ".") {
                return formattedNumber + "0";
            }
            return formattedNumber;
        }

        // Note: This formats currency inputs on blur (not on keyup).
        // This provides a more predictable data entry experience for the user.
        $("input.currency").blur(function () {
            var value = $(this).val();
            $(this).val(formatCurrency(value));
        });

    }(jQuery));

    // End jQuery Document Ready
});