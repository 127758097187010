$(document).ready(function () {
    "use strict";

    (function ($) {

        // Blood Pressure
        // ==============

        // On load

        if ($("#IsBloodPressureKnown_Yes").prop("checked")) {
            $("#IsBloodPressureKnown_Yes_SH").show();
        } else {
            $("#IsBloodPressureKnown_Yes_SH").hide();
        }

        // On change

        var bloodpressurearray = [
            "IsBloodPressureKnown_Yes",
            "IsBloodPressureKnown_No"
        ];

        $.each(bloodpressurearray, function (index, value) {
            $("#" + value).change(function () {
                if ($("#IsBloodPressureKnown_Yes").prop("checked")) {
                    $("#IsBloodPressureKnown_Yes_SH").show();
                } else {
                    $("#IsBloodPressureKnown_Yes_SH").hide();
                }
            });
        });

        // Cholesterol
        // ===========

        // On load

        if ($("#IsCholesterolRatioKnown_Yes").prop("checked")) {
            $("#IsCholesterolRatioKnown_Yes_SH").show();
        } else {
            $("#IsCholesterolRatioKnown_Yes_SH").hide();
        }

        // On change

        var cholesterolarray = [
            "IsCholesterolRatioKnown_Yes",
            "IsCholesterolRatioKnown_No"
        ];

        $.each(cholesterolarray, function (index, value) {
            $("#" + value).change(function () {
                if ($("#IsCholesterolRatioKnown_Yes").prop("checked")) {
                    $("#IsCholesterolRatioKnown_Yes_SH").show();
                } else {
                    $("#IsCholesterolRatioKnown_Yes_SH").hide();
                }
            });
        });

        // Smoker
        // ======

        // On load

        if ($("#IsSmoker_Yes").prop("checked")) {
            $("#IsSmoker_Yes_SH").show();
        } else {
            $("#SmokerYes1").prop("checked", false);
            $("#SmokerYes2").prop("checked", false);
            $("#SmokerYes3").prop("checked", false);
            $("#SmokerYes4").prop("checked", false);
            $("#IsSmoker_Yes_SH").hide();
        }

        if ($("#IsSmoker_No").prop("checked")) {
            $("#IsSmoker_No_SH").show();
        } else {
            $("#SmokerNo1").prop("checked", false);
            $("#SmokerNo2").prop("checked", false);
            $("#SmokerNo3").prop("checked", false);
            $("#SmokerNo4").prop("checked", false);
            $("#IsSmoker_No_SH").hide();
        }

        // On change

        var smokerarray = [
            "IsSmoker_Yes",
            "IsSmoker_No"
        ];

        $.each(smokerarray, function (index, value) {
            $("#" + value).change(function () {
                if ($("#IsSmoker_Yes").prop("checked")) {
                    $("#IsSmoker_Yes_SH").show();
                    $("#SmokerNo1").prop("checked", false);
                    $("#SmokerNo2").prop("checked", false);
                    $("#SmokerNo3").prop("checked", false);
                    $("#SmokerNo4").prop("checked", false);
                    $("#IsSmoker_No_SH").hide();
                } else {
                    $("#IsSmoker_No_SH").show();
                    $("#SmokerYes1").prop("checked", false);
                    $("#SmokerYes2").prop("checked", false);
                    $("#SmokerYes3").prop("checked", false);
                    $("#SmokerYes4").prop("checked", false);
                    $("#IsSmoker_Yes_SH").hide();
                }
            });
        });

        // Check-up
        // ========

        // On load

        if ($("#HasHadCheckup_Yes").prop("checked")) {
            $("#HasHadCheckup_Yes_SH").show();
        } else {
            $("#HasHadCheckup_Yes_SH").hide();
        }

        // On change

        var hashadcheckup = [
            "HasHadCheckup_Yes",
            "HasHadCheckup_No"
        ];

        $.each(hashadcheckup, function (index, value) {
            $("#" + value).change(function () {
                if ($("#HasHadCheckup_Yes").prop("checked")) {
                    $("#HasHadCheckup_Yes_SH").show();
                } else {
                    $("#HasHadCheckup_Yes_SH").hide();
                }
            });
        });

    }(jQuery));

    // End jQuery Document Ready
});