$(document).ready(function () {
    "use strict";

    (function ($) {

        // Open External Links in a New Window
        // ===================================

        $("a[href*='http://']:not([href*='" + location.hostname + "']),[href*='https://']:not([href*='" + location.hostname + "'])").click(function () {
            window.open(this.href);
            return false;
        });

    }(jQuery));

    // End jQuery Document Ready
});